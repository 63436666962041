import React from "react"
import { Helmet } from "react-helmet"

function SEOYoko() {
  return (
    <Helmet>
      <meta charset="utf-8" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <title>Monacard YOKO</title>
      <meta name="description" content="横向きのモナカードを試してみる。" />
      <link rel="shortcut icon" href="https://faucet.kotaroo.work/favicon.png"></link>
      <meta property="og:url" content="https://faucet.kotaroo.work/" />
      <meta property="og:title" content="Monacard YOKO" />
      <meta property="og:image" content="https://faucet.kotaroo.work/monacard_yoko_ogp.png" />
      <meta property="og:description" content="横向きのモナカードを試してみる。" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Monacard YOKO" />
      <meta name="twitter:image" content="https://faucet.kotaroo.work/monacard_yoko_ogp.png" />
      <meta name="twitter:description" content="横向きのモナカードを試してみる。" />
      <meta name="twitter:site" content="@7101kerokero" />
    </Helmet>
  )
}
export default SEOYoko;
