const URL_LIST = [
  "https://xmp-kojiki.herokuapp.com/api", // product: 0
] 
export const PARAMS = {
  URL: URL_LIST[0], 
  END_POINTS: {
    reqNumber: "/req_number",
    verifyAnswer: "/verify_answer",
    requestXMP: "/request_xmp",
    checkAddress: "/check_address"
  }
};

/**
 * Emoji Text
 */
 export const Emoji = {
  flashEmoji: "\u{1F4AB}",
  alienEmoji: "\u{1F47E}",
  checkEmoji: "\u{2714}",
  batsuEmoji: "\u{274C}",
  starEmoji: "\u{2B50}",
  rocketEmoji: "\u{1F680}",
  tresureEmoji: "\u{1F48E}",
  fujisannEmoji: "\u{1F5FB}",
  upEmoji: "\u{2B06}",
  clockEmoji: "\u{23F0}",
  musiumEmoji: "\u{1F3DB}",
  searchEmoji: "\u{1F50E}",
}

/**
 * Unescape Process
 * @param targetText
 * @returns after processing text
 */
export function unescapeProcess(targetText) {
  let result = targetText;
  result = result.replace(/&amp;/g, "&");
  result = result.replace(/&quot;/g, "\"");
  result = result.replace(/&#039;/g, "\'");
  result = result.replace(/&lt;/g, "<");
  result = result.replace(/&gt;/g, ">");
  return result
}

/**
 * Escape Process
 * @param targetText
 * @returns after processing text
 */
export function escapeProcess(targetText) {
  let result = targetText;
  result = result.replace(/&/g, "&amp;");
  result = result.replace(/\"/g, "&quot;");
  result = result.replace(/\'/g, "&#039;");
  result = result.replace(/</g, "&lt;");
  result = result.replace(/>/g, "&gt;");
  return result
}
