import React, { useState } from 'react';
import "../style/styles.css"
import { getJoinedMonacardList, getMonacardInfoMany, getAssetsBalancesElectrum } from "../components/Mpurse";
import { unescapeProcess } from '../components/Util';
import SEOYoko from '../components/SeoYoko';

const useMonacard_yoko = () => {
  const [ monacardHTML, setmonacardHTML ] = useState(<></>);
  let userAddress = "";
  const monacardInfo = [];

  function getAddress(e) {
    userAddress = e.target.value;
  }

  async function reqMonacardInfo() {

    let assetTotalList = [];
    let assetTotalListBase = [];
    let monacardList = null;
  
    if(assetTotalList.length > 0) return;
  
    const assetsBalances = await getAssetsBalancesElectrum(userAddress);
    if(assetsBalances === undefined) return false;
    if(assetsBalances.length === 0) return false;
    const joinedAssetList = getJoinedMonacardList(assetsBalances);
    monacardList = await getMonacardInfoMany(joinedAssetList);
  
    for(let i = 0; i < monacardList.length; i++) {
     
      for(let ii = 0; ii < monacardList[i].length; ii++) {
      
        setMonacardInfo(monacardList[i][ii]);
  
      }
    }
    
    for(let i in monacardInfo) {
  
      let cardLink;
      let cardImage = new Boolean(true);

      if(monacardInfo[i].imgur_url === undefined) {
        cardImage = false;
      } else {
        if(monacardInfo[i].imgur_url.slice(0, 22) === "https://img.monaffy.jp") {
          cardLink = monacardInfo[i].imgur_url.replace(/original/, "preview");
        } else if(monacardInfo[i].imgur_url === "/images/default_canvas.png") {
          cardLink = monacardInfo[i].imgur_url;
        } else if(monacardInfo[i].imgur_url.slice(-3) === "png") {
          cardLink = monacardInfo[i].imgur_url.replace(".png", "l.png");
        } else if(monacardInfo[i].imgur_url.slice(-3) === "jpg") {
          cardLink = monacardInfo[i].imgur_url.replace(".jpg", "l.jpg");
        } else {
          cardLink = monacardInfo[i].imgur_url;
        }
      }

      const checkRotateState = monacardInfo[i].add_description.includes("OPT_ROT");
      const imageHTML = () => {
        if(checkRotateState) { 
          return (
            <>
             <div className="rounded-lg border-2 border-black col-span-2">
                <div className="transform hover:scale-105 w-full p-4 flex justify-center">
                  <img className="rounded-lg shadow-lg origin-center transform -rotate-90 w-1/2" src={ cardLink } />
                </div>
                <div className="font-medium md:font-semibold w-full break-all text-sm px-2 text-left">
                  <div className="">{ monacardInfo[i].assetName }</div>
                </div>
              </div>
            </>
          );
        } else {
          return (
            <>
            <div className="rounded-lg border-2 border-black">
              <div className="transform hover:scale-105 w-full p-4">
                <img className="rounded-lg shadow-xl" src={ cardLink } />
              </div>
              <div className="font-medium md:font-semibold w-full break-all text-sm px-2 text-left">
                <div className="">{ monacardInfo[i].assetName }</div>
              </div>
            </div>
            </>
          );
        }
      }
      
      if(cardImage) {
        assetTotalListBase.push([
          monacardInfo[i].assetName,
          <>{ imageHTML() }</>
        ]);
        assetTotalList.push(assetTotalListBase[i][1]);
      }
    }
    setmonacardHTML(assetTotalList);
  }

  function setMonacardInfo(monacardList) {
    monacardInfo.push(
      {
        assetName: monacardList.asset_longname === null ? monacardList.asset_common_name: monacardList.asset_longname,
        assetgroup: monacardList.assetgroup,
        card_name: unescapeProcess(monacardList.card_name),
        owner_name: unescapeProcess(monacardList.owner_name),
        imgur_url: unescapeProcess(monacardList.imgur_url),
        add_description: unescapeProcess(monacardList.add_description),
        tw_id: unescapeProcess(monacardList.tw_id),
        tw_name: unescapeProcess(monacardList.tw_name),
        tag: unescapeProcess(monacardList.tag),
        cid: monacardList.cid,
        ver: monacardList.ver,
        is_good_status: monacardList.asseis_good_statustgroup,
        regist_time: monacardList.regist_time,
        update_time: monacardList.update_time,
      }
    );
  }

  return (
    <>
      <SEOYoko />
      <div className="w-full h-full p-4 md:py-12 mx-auto flex justify-center items-center">
        <div>
          <p className="my-4 font-bold text-3xl md:text-4xl text-center">横向きモナカード（お試し）</p>
          <div className="p-4">
            <p className="mb-4 font-bold text-xl md:text-2xl underline">試し方</p>
            <p className="font-bold text-sm md:text-base">・モナカードの "Description（カードの説明）" の最後に "OPT_ROT" と付けます。</p>
            <p className="font-bold text-sm md:text-base">・モナカードで画像登録は縦向きのまま行います。</p>
            <p className="font-bold text-sm md:text-base">・このサイトでは上記の "OPT_ROT" が付いたカードを判別して横向きに表示しています。</p>
          </div>
          <div className="p-4">
            <p className="mb-4 font-bold text-xl md:text-2xl underline">注意</p>
            <p className="font-bold text-sm md:text-base">・カードは左90°（反時計回り）のみに回転します。</p>
            <p className="font-bold text-sm md:text-base">・この試みはモナカード公式の仕様とは関係がなく、このサイト独自に試しているだけです。</p>
            <p className="font-bold text-sm md:text-base">・現状で他のサイトやアプリでは反映されません。</p>
          </div>
          <div className="p-4">
            <p className="mb-4 font-bold text-xl md:text-2xl underline">今後</p>
            <p className="font-bold text-sm md:text-base">・このサイトや仕様は予告なく終了する可能性があります。</p>
            <p className="font-bold text-sm md:text-base">・物は試しでやってるので即刻ボツになるかもしれません。</p>
            <p className="font-bold text-sm md:text-base">・多くの人が気に入れば他のアプリにも広まるかもしれません。</p>
          </div>
          <div className="p-4">
            <p className="mb-4 font-bold text-xl md:text-2xl underline">やってみて</p>
            <p className="font-bold text-sm md:text-base">・既に出来上がってるレイアウトに組み込むのでCSSの対応が大変。</p>
            <p className="font-bold text-sm md:text-base">・縦横のカードをキレイに見せるのに悩みそう。</p>
            <p className="font-bold text-sm md:text-base">・カードの表現の幅が広がりそう。</p>
          </div>
          <div className="p-4">
            <p className="mb-4 font-bold text-xl md:text-2xl underline">サンプル</p>
            <p className="font-bold text-sm md:text-base">・下記のアドレスで試してみてね。</p>
            <p className="font-bold text-sm md:text-base">MSgQuJGBkbnnV9i6ZozUfaRNmkq9j5tL3W</p>
            <p className="font-bold text-sm md:text-base underline"><a href="https://card.mona.jp/explorer/card_detail?asset=TESTMONA#">・サンプルカードのリンク。</a></p>
          </div>
          <div className="my-4 flex flex-wrap justify-center md:justify-between items-start">
            <input id="address" onChange={ getAddress } className="w-full mb-4 p-8 rounded-lg border-2 border-solid border-black font-black" type="text" name="address" placeholder="ADDRESS"></input>
            <button onClick={ reqMonacardInfo } className="w-full p-8 rounded-lg border-2 border-solid border-black font-black bg-black text-white">REQUEST</button>
          </div>
          <div className="my-4 px-2 py-4 text-lg grid grid-flow-row-dense grid-cols-2 sm:grid-cols-3 gap-2 overflow-auto">
            { monacardHTML }
          </div>
        </div>
      </div>
    </>
  );
}
export default useMonacard_yoko;
